<template>
  <div class="role-list">
    <app-tree :opt="opt">
      <template slot="info">
        <el-tabs v-model="activeName" class="info-box">
          <el-tab-pane label="菜单信息" name="form">
            <el-form :model="form" label-width="80px" disabled>
              <el-form-item label="菜单名称">
                <el-input v-model="form.title"></el-input>
              </el-form-item>
              <el-form-item label="上级菜单">
                <el-input v-model="form.ptitle"></el-input>
              </el-form-item>
              <el-form-item label="资源路径">
                <el-input v-model="form.apiUrl"></el-input>
              </el-form-item>
              <el-form-item label="菜单类型">
                <el-input v-model="form.menuType"></el-input>
              </el-form-item>
              <el-form-item label="添加时间">
                <el-input v-model="form.createTime"></el-input>
              </el-form-item>
              <el-form-item label="菜单描述">
                <el-input type="textarea" v-model="form.remark"></el-input>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <!-- <el-tab-pane label="关联资源" name="tree">
            <el-tree
              default-expand-all
              :data="data"
              :props="{
                children: 'children',
                label: 'label'
              }"
            ></el-tree>
          </el-tab-pane> -->
        </el-tabs>
      </template>
    </app-tree>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      opt: {
        title: "菜单管理",
        buttons: [
          // {
          //   name: "新增",
          //   icon: "el-icon-plus",
          //   style: "primary",
          //   type: 1,
          //   on(data) {
          //     console.log(data);
          //     _this.cache.set("roleParent", data.data);
          //     _this.$router.push("/main/menu/detail/add/0");
          //   }
          // },
          // {
          //   type: 1,
          //   on(data) {
          //     _this.$router.push("/main/menu/detail/edit/" + data.id);
          //   }
          // },
          // {
          //   type: 2,
          //   on(data) {
          //     var dto = {
          //       ids: data.ids
          //     };
          //     _this.post("/user/del", dto).then(function() {
          //       _this.$message({
          //         showClose: true,
          //         message: "删除成功!",
          //         type: "success"
          //       });
          //       data.refresh();
          //     });
          //   }
          // }
        ],
        get(opt) {
          _this.post("user-service/menu/queryallmenu", {}).then(res => {
            res.splice(0, 0, res[res.length - 1])
            res.splice(res.length - 1, 1)
            res = JSON.parse(JSON.stringify(res).replace(/menuId/g,"id"));
            res = JSON.parse(JSON.stringify(res).replace(/title/g,"label"));
            opt.cb(res);
          })
        },
        on(data) {
          _this.post("user-service/menu/querymenuinfo", {menuId: data.id}).then(data => {
            _this.form.title = data.title;
            _this.form.ptitle = data.ptitle;
            _this.form.apiUrl = data.apiUrl;
            _this.form.menuType = ["资源菜单", "功能按钮"][data.menuType - 1];
            _this.form.createTime = _this.format(data.createTime);
            _this.form.remark = data.remark;
          });
        }
      },
      activeName: "form",
      form: {
        title: "",
        ptitle: '',
        apiUrl: '',
        menuType: '',
        createTime: '',
        remark: ''
      },
      data: [
        {
          label: "一级 1",
          children: [
            {
              label: "二级 1-1",
              children: [
                {
                  label: "三级 1-1-1"
                }
              ]
            }
          ]
        }
      ]
    };
  },
  created() {
    console.log("role-list created!!");
  }
};
</script>