var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "role-list" },
    [
      _c(
        "app-tree",
        { attrs: { opt: _vm.opt } },
        [
          _c(
            "template",
            { slot: "info" },
            [
              _c(
                "el-tabs",
                {
                  staticClass: "info-box",
                  model: {
                    value: _vm.activeName,
                    callback: function($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName"
                  }
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "菜单信息", name: "form" } },
                    [
                      _c(
                        "el-form",
                        {
                          attrs: {
                            model: _vm.form,
                            "label-width": "80px",
                            disabled: ""
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "菜单名称" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.form.title,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "title", $$v)
                                  },
                                  expression: "form.title"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "上级菜单" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.form.ptitle,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "ptitle", $$v)
                                  },
                                  expression: "form.ptitle"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "资源路径" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.form.apiUrl,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "apiUrl", $$v)
                                  },
                                  expression: "form.apiUrl"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "菜单类型" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.form.menuType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "menuType", $$v)
                                  },
                                  expression: "form.menuType"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "添加时间" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.form.createTime,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "createTime", $$v)
                                  },
                                  expression: "form.createTime"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "菜单描述" } },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea" },
                                model: {
                                  value: _vm.form.remark,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "remark", $$v)
                                  },
                                  expression: "form.remark"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }